<template>
    <error-content code="" desc="系统使用时限已到期，请联系管理员" :src="src"/>
</template>

<script>

import error401 from '@/assets/images/error/error-401.svg'
import errorContent from './index.vue'
export default {
    components: {
        errorContent
    },
    data () {
        return {
            src: error401
        }
    },
    mounted(){
        window.localStorage.clear();
    }
}
</script>

<style scoped lang="less">
    /deep/ .text-con{
        h5{
            margin-top: -100px;
        }
    }
</style>