<template>
    <div class="error">
        <div class="content-con">
            <img :src="src" :alt="code">
            <div class="text-con">
                <h4>{{ code }}</h4>
                <h5>{{ desc }}</h5>
            </div>
            <Back />
        </div>
    </div>
</template>

<script>
import Back from './back.vue'
export default {
    components:{
        Back,
    },
    props: {
        code: String,
        desc: String,
        src: String,
    }
}
</script>